import React from 'react';
import { map } from 'lodash';

const Users = ({ users, handleRowClick }) => {
  return (
    <>
      <table className="table -fixed">
        <tbody>
          <tr className="tr-head -sticky">
            <th className="-verizon_header">
              First Name
            </th>
            <th className="-verizon_header -sample">
              Last Name
            </th>
            <th className="-verizon_header">
              Email
            </th>
            <th className="-verizon_header">
              Visibility Groups
            </th>
          </tr>
          {
            map(users, (user) => (
              <tr className="body-row" key={ user.id } onClick={ () => {handleRowClick(user);} }>
                <td className="-verizon_cell -pointer">
                  {user.first_name}
                </td>
                <td className="-verizon_cell -pointer">
                  {user.last_name}
                </td>
                <td className="-verizon_cell -pointer">
                  {user.email}
                </td>
                <td className="-verizon_cell -pointer">
                  {map(user.visibility_groups, (vg) => (
                    <div key={ vg.value } className="table_status-label -tag">
                      {vg.label}
                    </div>
                  ))}
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </>
  );
};

export default Users;
