import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { findIndex, map, sortBy } from 'lodash';
import Modal from '../../../common/components/modal';
import { vgSelectStyles } from "../projects/components/helpers";
import Http from '../../../common/http';
import cn from "classnames";

const VisibilityGroupModal = ({ users, setUsers, user, setUser, closeModal, visibilityGroups }) => {
  const [ dirtyValues, setDirtyValues ] = useState(user.visibility_groups);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState("");

  const handleOnChange = (newValues) => {
    setDirtyValues(map(newValues, (el) => {
      return { value: el.__isNew__ ? '' : el.value, label: el.label };
    }));
  };

  const handleApply = (deleteMode) => {
    setLoading(true);
    Http.post(
      `/verizon_customer_case_v2024/api/users/visibility_groups`,
      {
        user_id: user.id,
        email: user.email,
        visibility_groups: deleteMode ? [] : dirtyValues
      }
    ).then(
      (http) => {
        if (http.response.status === 200) {
          if (http.data.errors) {
            setError(http.data.errors);
          } else {
            const newUser = http.data;

            const index = findIndex(users, (u) => (u.id === newUser.id));

            if (index === -1 && !!newUser.visibility_groups.length) {
              //add
              users.push(newUser);
            } else if (newUser.visibility_groups.length) {
              //update
              users[index] = newUser;
            } else {
              //remove
              if (index !== -1) {
                users.splice(index, 1);
              }
            }

            const sortedUsers = sortBy(users, [ (u) => (`${u.first_name.toLowerCase()}-${u.last_name}-${u.email}`) ]);
            setUsers([ ...sortedUsers ]);

            closeModal();
          }
        }
        setLoading(false);
      },
      (reject) => {
        console.log('something went wrong', reject);
        setLoading(false);
      });
  };

  return (
    <Modal
      modalClassName="centered"
      dialogClassName="verizon-modifier -visibility-groups"
      backDropModifier="verizon-modifier -visibility-groups"
      modalContentClass="-overflow-visible"
      modalSize="md"
      modal
    >
      <div className="summary-editor">
        <div className="summary-editor_header">
          <h3 className="summary-editor_title">
            { !user.id ? "Add Linked Groups" : "Edit Linked Groups" }
          </h3>
        </div>

        <div>
          <h3>Email</h3>
          <div className="summary-editor_form-group">
            <input
              className="form-field -block"
              maxLength="250"
              size="250"
              type="text"
              value={ user.email }
              onChange={ (ev) => { setUser({ ...user, email: ev.target.value }); } }
              disabled={ !!user.id || loading }
            />
            {!!error && <div className="form-error">{error}</div>}
          </div>

          <h3>Visibility Groups</h3>
          <div className="summary-editor_form-group">
            <CreatableSelect
              defaultValue={ user.visibility_groups }
              className="react-select-container"
              classNamePrefix="react-select"
              options={ visibilityGroups }
              onChange={ handleOnChange }
              isSearchable
              isMulti
              autosize={ false }
              maxMenuHeight={ 150 }
              styles={ vgSelectStyles }
              isDisabled={ loading }
            />
          </div>
        </div>
        <div className={ cn("summary-editor_footer", { "-flex": !!user.id }) }>
          {
            !!user.id &&
            <button
              className="button -vcc-delete"
              type="button"
              onClick={ () => { handleApply(true) } }
              disabled={ loading }
            >
              Delete
            </button>
          }
          <div className="summary-editor_footer-buttons -right">
            <button
              className="button -default"
              type="button"
              onClick={ closeModal }
              disabled={ loading }
            >
              Cancel
            </button>
            <button
              className="button"
              type="button"
              onClick={ () => { handleApply(false) } }
              disabled={ loading || !dirtyValues.length || !user.email }
            >
              Apply Changes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VisibilityGroupModal;
