import { baseFakeData, cashbackMoney } from '../../common/baseFakeData';
export const fakeData = {
  ...baseFakeData,
  ...{
    introSetupList: [
      {
        id: "pay",
        title: "Pay in stores with PayPal Debit Card",
        text: "Sign up for free and add it to Apple Pay or your digital wallet and you’re good to go."
      },
      {
        id: "earn",
        title: "Earn 5% cash back everywhere",
        text: "Online and in stores. In a category you get to choose every month with PayPal Debit card."
      },
      {
        id: "rewards",
        title: `Plus a $${cashbackMoney} cash back bonus`,
        rewards: true
      }
    ]
  }
};
