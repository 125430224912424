import { baseFakeData } from '../../common/baseFakeData';
export const fakeData = {
  ...baseFakeData,
  ...{
    introSetupList: [
      {
        id: "pay",
        title: "Pay in stores with PayPal Debit Card",
        text: "Sign up for free and add it to Apple Pay or your digital wallet and you’re good to go."
      },
      {
        id: "earn",
        title: "Earn 5% cash back everywhere",
        text: "Online and in stores. In a category you get to choose every month with PayPal Debit card."
      },
      {
        id: "cashback",
        title: "And get even more cash back",
        text: "On 100s of brands. Combine offers with your PayPal Debit monthly category to maximize cash back."
      },
      {
        id: "security",
        title: "With safety and security built in",
        text: "No liability on unauthorized purchases."
      }
    ]
  }
};
