import { values, find, map, compact, findIndex } from 'lodash';
import { baseFakeData } from '../common/baseFakeData';

const initialData = () => ({ startTime: Date.now() });

const allPossibleSteps = [
  'splash', // Splash Native notification
  'welcome', // Welcome Screen
  'email', // Email screen
  'mobile_number', // Mobile Number screen
  'passkey', // Passkey screen
  'password_password', // Password screen - input password screen
  'password_save_password_to_device', // Password screen - save password to device Native Notification
  'personal_info', // Personal Info screen
  'faceid_notification', // Face ID Native notification screen
  'intro_setup', // Intro Screen
  'benefits', // Benefits Screen
  'id_check', // ID Check Screen
  'base_intent', // Base Intent Screen
  'base_add_card', // Intent - Add Card Screen
  'shopping_intent_and_notifications_shopping_intent', // Intent - Shopping Intent Screen
  'shopping_intent_and_notifications_contact_sync', // Intent - Contact Sync Screen
  'shopping_intent_and_notifications_access_contacts', // Intent - Contact Sync Native notification Screen
  'shopping_intent_and_notifications_notifications', // Intent - Get Updates Screen
  'shopping_intent_and_notifications_base_notifications', // Intent - Get Updates Native notification Screen
  'ppe_cashback_category', // Debit Category selection screen
  'ppe_autoreload_autoReload', // AutoReload screen - AutoReload form
  'ppe_autoreload_OneTime', // AutoReload screen - OneTime Transfer form
  'card_to_wallet_first_page', // Add Card To Wallet - First page Screen
  'card_to_wallet_native_apple_pay', // Add Card To Wallet - Native Adding Screens
  'card_to_wallet_card_pin', // Add Card To Wallet - Create Pin Screen
  'card_to_wallet_notifications_ask', // Add Card To Wallet - Get Updates Screen
  'card_to_wallet_notification_native' // Add Card To Wallet - Get Updates Native notification Screen
];

const finishingData = (existingData) => {
  const hash = {
    totalTime: Math.floor((Date.now() - existingData.startTime) / 1000),
    intentCategoriesIds: map(existingData.intentCategories || [], (intentCategoryName) => {
      const foundEl = find(
        baseFakeData.interestsList, (el) => (el.id === intentCategoryName)
      );
      return foundEl?.innerIndex;
    }),
    cashbackCategoriesIds: map(existingData.cashbackCategories || [], (cashbackCategoryName) => {
      const foundEl = find(
        baseFakeData.cashBackCategoriesList, (el) => (el.id === cashbackCategoryName)
      );
      return foundEl?.innerIndex;
    }),
    cashbackCategoryId: find(
      baseFakeData.cashBackCategoriesListShort,
      (el) => (el.id === existingData.cashbackCategory)
    )?.innerIndex,
    interruptedStepId: findIndex(
      allPossibleSteps,
      (stepEl) => {
        let generatedId = compact(
          [ existingData.interruptAppTopStep, existingData.interruptAppInnerStep ]
        ).join('_');
        if (existingData.interruptAppTopStep === 'ppe_autoreload') {
          generatedId = [
            generatedId,
            existingData.oneTimeSelected ? 'OneTime' : 'autoReload'
          ].join('_');
        }
        return stepEl === generatedId;
      }
    ) + 1,
    manualStateId: findIndex(baseFakeData.usStates, (el) => (
      el.value === existingData.manualState
    )) + 1
  };

  if (existingData.address) {
    hash.address = values(existingData.address).join(', ');
  }
  if (existingData.billingAddress) {
    hash.billingAddress = values(existingData.billingAddress).join(', ');
  }
  return hash;
};

const sendData = (customData = {}) => {
  const postData = {
    result: customData || {},
    kind: 'finishStimuli'
  };

  (window.parent || window).postMessage(postData, "*");
};

export { initialData, finishingData, sendData };
