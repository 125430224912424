import React, { useContext } from 'react';
import { IndexData } from "../../common/index-context";
import GreenRed from './greenRed';
import { HeaderRow, TableHeaderRow, DataRow } from './benchmarksNumbers';

const DeviationsNumbers = () => {
  const { data } = useContext(IndexData);

  return (
    <>
      {
        data.benchmarks &&
        <>
          <h2 className="comparisons_section-title">{`Unhappy Path Impact Benchmarks Among ${data.segment_full_text}`}</h2>

          <table className="table scorecard-table">
            <tbody>
              <TableHeaderRow />

              <HeaderRow text="Shift in Ease of Experience" />
              <DataRow
                text="No effort/a little effort"
                benchmarkData={ data.benchmarks.vcc_deviation_effort }
                needPercents={ false }
              />

              <HeaderRow text="Full Idea" />
              <DataRow
                text={ data.branded_texts.vcc_deviation_values }
                benchmarkData={ data.benchmarks.vcc_deviation_values }
                needPercents={ false }
              />
            </tbody>
          </table>
          <div className="table_legend">
            <GreenRed />
          </div>
        </>
      }
    </>
  );
};

export default DeviationsNumbers;
