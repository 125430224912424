import React, { useState } from 'react';
import PoweredByBuzzback
  from '../../../express/researcher/verizon_customer_case_v2024/common/components/poweredByBuzzback';
import Users from './users';
import VisibilityGroupModal from './visibilityGroupModal';

const App = (data) => {
  const [ showModal, setShowModal ] = useState(false);
  const [ editedUser, setEditedUser ] = useState({});
  const [ users, setUsers ] = useState(data.users);

  const handleLink = () => {
    setEditedUser({ id: '', email: '', visibility_groups: [] });
    setShowModal(true);
  };

  const handleRowClick = (user) => {
    setEditedUser(user);
    setShowModal(true);
  };

  return (
    <div className="content -grey-background verizon-modifier -comparison -research">
      <div className="content_top -sticky -white">
        <header className="header">
          <div className="header_grid">
            <div className="header_grid-item">
              <h1 className="header_title">User Groups</h1>
            </div>

            <div className="header_grid-item -gl-buttons">
              <button
                className="button"
                onClick={ handleLink }
              >
                Link with group
              </button>
            </div>

          </div>
        </header>
      </div>

      <div className="content_body -relative -projects">
        <div className="projects">
          <div className="projects_list_table">
            <Users
              users={ users }
              handleRowClick={ handleRowClick }
            />
          </div>
        </div>
      </div>

      {showModal &&
        <VisibilityGroupModal
          users={ users }
          setUsers={ setUsers }
          user={ editedUser }
          setUser={ setEditedUser }
          closeModal={ () => { setShowModal(false); } }
          visibilityGroups={ data.visibility_groups }
        />}

      <PoweredByBuzzback />
    </div>
  );
};

export default App;
