import React from 'react';
import OnboardingBauApp from './bau/onboardingApp';
import ReboardingBauApp from './bau/reboardingApp';

const PaypalEverywhereApp = ({ conceptName }) => {
  switch (conceptName) {
    case 'BAU-ONBOARDING':
      return (<OnboardingBauApp />);
    case 'BAU-REBOARDING':
      return (<ReboardingBauApp />);
    default:
      return (<>Incorrect Concept</>);
  }
};

export default PaypalEverywhereApp;
