import React, { useState, useRef } from 'react';
import { find } from "lodash";
import classnames from 'classnames';
import LoadingPage from "../common/screens/loadingPage";
import Benefits from "../common/screens/benefits";
import IdCheck from "../common/screens/idCheck";
import PPECashbackCategory from "../common/screens/ppeCashbackCategory";
import PPEAutoReload from "../common/screens/ppeAutoreload";
import { GlobalContext } from '../common/contexts/appContexts';
import Splash from '../common/screens/splash';
import CardToWallet from '../common/screens/cardToWallet';
import Celebration from '../common/screens/celebration';
import CloseFollowUp from '../common/components/closeFollowUp';
import IntroSetup from "./reboarding/introSetup";
import { fakeData } from './reboarding/fakeData';
import { initialData, sendData, finishingData } from './statistics';

const ReboardingBauApp = () => {
  const [ stepsHistory, setStepsHistory ] = useState([]);
  const [ baseInnerSteps, setBaseInnerSteps ] = useState([]);
  const [ innerStep, setInnerStep ] = useState(null);
  const [ showFollowUpModal, setShowFollowUpModal ] = useState(false);
  const currentDataRef = useRef({
    ...initialData(),
    ...{
      firstName: fakeData.firstName,
      lastName: fakeData.lastName,
      address: fakeData.addressList[fakeData.addressList.length - 1]
    }
  });

  const mergeData = (newHash) => {
    currentDataRef.current = { ...currentDataRef.current, ...newHash };
  };

  const checkIfAllSetup = () => (
    currentDataRef.current.cashbackCategoryChosen &&
      currentDataRef.current.autoReload &&
      currentDataRef.current.cardIssued &&
      currentDataRef.current.pinSet
  );

  const finishApp = (addition = {}) => {
    sendData(
      finishingData({
        ...currentDataRef.current,
        ...(addition || {})
      })
    );
  };

  const [ stepsOrderList ] = useState(() => (
    [
      {
        step: 'splash',
        nextStep: () => ('intro_setup'),
        skipStep: null
      },
      {
        step: 'intro_setup',
        nextStep: () => ('id_check'),
        skipStep: () => ('benefits')
      },
      {
        step: 'benefits',
        nextStep: () => ('id_check'),
        skipStep: () => {
          finishApp();
          return null;
        }
      },
      {
        step: 'id_check',
        nextStep: () => ('ppe_loading'),
        skipStep: () => () => {
          finishApp();
          return null;
        }
      },
      {
        step: 'ppe_loading',
        nextStep: () => ('ppe_cashback_category'),
        skipStep: null
      },
      {
        step: 'ppe_cashback_category',
        nextStep: () => ('ppe_autoreload'),
        skipStep: () => ('ppe_autoreload')
      },
      {
        step: 'ppe_autoreload',
        nextStep: () => ('card_to_wallet'),
        skipStep: () => ('card_to_wallet')
      },
      {
        step: 'card_to_wallet',
        nextStep: () => (checkIfAllSetup() ? 'full_celebration' : 'low_celebration'),
        skipStep: () => (checkIfAllSetup() ? 'full_celebration' : 'low_celebration')
      },
      {
        step: 'low_celebration',
        nextStep: () => {
          finishApp();
          return null;
        },
        skipStep: null
      },
      {
        step: 'full_celebration',
        nextStep: () => {
          finishApp({ fullSetup: true });
          return null;
        },
        skipStep: null
      }
    ]
  ));
  const [ step, setStep ] = useState(stepsOrderList[0].step);

  const goForward = () => {
    const currentStepInfo = find(stepsOrderList, (stepsItem) => (stepsItem.step === step));
    if (currentStepInfo?.nextStep) {
      stepsHistory.push(currentStepInfo.step);
      setStepsHistory([ ...stepsHistory ]);
      setStep(currentStepInfo?.nextStep());
      return;
    }
    setStep(null);
  };

  const goBack = () => {
    if (stepsHistory.length) {
      setStep(stepsHistory[stepsHistory.length - 1]);
      stepsHistory.splice(stepsHistory.length - 1, 1);
      setStepsHistory([ ...stepsHistory ]);
      return;
    }
    setStep(null);
  };

  const goSkip = () => {
    const currentStepInfo = find(stepsOrderList, (stepsItem) => (stepsItem.step === step));
    if (currentStepInfo?.skipStep) {
      stepsHistory.push(currentStepInfo.step);
      setStepsHistory([ ...stepsHistory ]);
      setStep(currentStepInfo?.skipStep());
      return;
    }

    setStep(null);
  };

  const handleInterruptApp = () => {
    finishApp({
      interruptAppYesClick: false,
      interruptAppTopStep: step,
      interruptAppInnerStep: innerStep
    });
    setShowFollowUpModal(false);
    setStep(null);
  };

  const handleInterruptAppSkip = () => {
    mergeData({ interruptAppNoClick: true });
    setShowFollowUpModal(false);
  };

  const globalContextValue = {
    fakeData,
    nextStep: goForward,
    prevStep: goBack,
    skipStep: goSkip,
    currentData: (currentDataRef.current),
    closeFullApp: () => {
      mergeData({ closeAppClick: true });
      setShowFollowUpModal(true);
    },
    mergeData, innerStep, setInnerStep,
    baseInnerSteps, setBaseInnerSteps
  };
  return (
    <GlobalContext.Provider value={ globalContextValue }>
      <div className={ classnames("container", { "-whole-white": !step }) }>
        { step === 'splash' && <Splash /> }
        {
          step === 'intro_setup' &&
          <IntroSetup headerStep={ 0 } numberOfHeaderSteps={ 0 } />
        }
        { step === 'benefits' && <Benefits headerStep={ 0 } numberOfHeaderSteps={ 0 } /> }
        { step === 'id_check' && <IdCheck forceBack headerStep={ 1 } numberOfHeaderSteps={ 1 } /> }
        {
          step === 'ppe_loading' &&
          <LoadingPage
            title="Getting you ready to earn cash back and pay in stores..."
            duration={ 10000 }
            delay={ 500 }
          />
        }
        {
          step === 'ppe_cashback_category' &&
          <PPECashbackCategory headerStep={ 1 } numberOfHeaderSteps={ 1 } />
        }
        {
          step === 'ppe_autoreload' &&
          <PPEAutoReload headerStep={ 1 } numberOfHeaderSteps={ 1 } />
        }
        {
          step === 'card_to_wallet' &&
          <CardToWallet skipNotifications headerStep={ 1 } numberOfHeaderSteps={ 1 } />
        }
        {
          step === 'full_celebration' &&
          <Celebration
            title={ `All set, ${currentDataRef.current.firstName}! You’re ready to earn cash back, pay in stores, and more.` }
            text={ `Plus, you got your ${fakeData.cashBackBonus} cash back bonus. Happy spending.` }
          />
        }
        {
          step === 'low_celebration' &&
          <Celebration
            title={ `All set, ${currentDataRef.current.firstName}!` }
            text="You’re ready to pay in stores, earn cash back, and more. Time to explore."
          />
        }
        {
          showFollowUpModal &&
          <CloseFollowUp onCloseYes={ handleInterruptApp } onCloseNo={ handleInterruptAppSkip } />
        }
      </div>
    </GlobalContext.Provider>
  );
};

export default ReboardingBauApp;
